<script>
import User from "@/components/User.vue";
import ContextMenuFile from "@/components/ContextMenuFile.vue";
import OpenFile from '@/components/OpenFile.vue';

export default {
    name: "TopicListItem",
    components: {User,ContextMenuFile,OpenFile},
    data() {
        return {

            document_flow_urls_form: [],
            dialogOpenFile: false,
            fileOpen: {},

        }
    },

    props: {
        item: {
            type: Object,
            required: true
        }
    },

    methods: {
        deleteItem(item) {
            this.$emit('deleteItem', item);
        },

    }



}
</script>

<template>
    <div class="item pt-5">



        <div class="item__description">
            <div class="item__id text-color-light small-text">#{{ item.id }}</div>
            <div class="item__name">
                <ContextMenuFile :file="item" @add-document-form="addDocumentForm"  @open-file="openFileContext" class=" mr-auto"></ContextMenuFile>
            </div>
        </div>



        <div class="item__data" >
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('last_message')}}</div>
                </div>
                <div class="text-info__value">
                    {{item.topic.title }}
                </div>
            </div>
        </div>

        <div class="item__data" >
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('date_created')}}</div>
                </div>
                <div class="text-info__value">
                    {{ $moment(item.created_at).format('DD.MM.YYYY') }}
                </div>
            </div>
        </div>


        <div class="item__footer">
            <User :item="item.author" />
        </div>

        <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>
    </div>
</template>
