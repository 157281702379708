<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template>
                            {{ $t('storage') }}
                        </template>
                    </h1>
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...adminItems.map((item) => {
                            return {
                                ...item,
                                title: item.title
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="client_storage"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template  v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in storages"
                    :item="item"
                    :key="item.id"
                ></ListItem>
            </template>

            <template v-else>

                <v-col cols="12" class="px-0 mx-0" >

                    <v-list two-line class="background_color_transparent" >
                        <v-list-item-group
                            v-model="selected"
                            class="my-0 "

                        >

                            <v-list-item
                                v-for="(item, i) in storages"
                                :key="i"
                                :class="'rounded-sm  topic_background ' + ($vuetify.breakpoint.xsOnly ? 'pl-15' : 'px-3')"
                                class="mb-5 height_list_item"
                                :style="'border-color:' + item.color +' !important' "
                                @click="handleClick(item)"
                            >

                                <template v-slot:default="{ active }">




                                    <v-list-item-content class="ml-6 topic_padding_top0">

                                        <v-row class="topic_margin_top0">
                                            <v-col cols="12"  md="6" sm="5" lg="6" >
                                                <v-list-item-title class="topic_text">{{$t('order_number')}}</v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">
                                                    <ContextMenuFile :file="item" @add-document-form ="addDocumentForm"  @open-file="openFileContext" class=" mr-auto"></ContextMenuFile>
                                                </v-list-item-subtitle>
                                            </v-col>
                                            <v-col cols="12"  md="3" sm="4" lg="2"  >
                                                <v-list-item-title  v-text="$t('deal_number')"></v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{item.topic.title}}</v-list-item-subtitle>
                                            </v-col>
                                            <v-col cols="12"  md="3" sm="3" lg="2" >
                                                <v-list-item-title class="topic_text">{{$t('deal_status')}}</v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{item.author.name}}</v-list-item-subtitle>
                                            </v-col>

                                            <v-col cols="12"  md="4" sm="4" lg="2" class="padding_top10_bottom0">
                                                <v-list-item-title class="topic_text">{{$t('date_created')}}</v-list-item-title>
                                                <v-list-item-subtitle class="topic_title">{{ $moment(item.created_at).format('DD.MM.YYYY') }}</v-list-item-subtitle>
                                            </v-col>

                                        </v-row>


                                    </v-list-item-content>

                                </template>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>


                </v-col>
                <div class="main-pagination d-flex flex-row justify-end">
                    <SelectInput
                        class="main-pagination__per-page"
                        id="pagination-variants"
                        :value="options.itemsPerPage"
                        :items="perPageItems"
                        :label="$t('items_per_page')"
                        :disabled="loading"
                        @input="onPerPageChange($event)"
                        hide-label
                    ></SelectInput>
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
                </div>
            </template>


            <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>

        </template>

        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5"  color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>
            <v-list class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon  v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>

    import { mapGetters } from "vuex"
    import FilterView from "@/plugins/mixins/FilterView";
    import ResourceView from "@/plugins/mixins/ResourceView";
    import NavMixin from "@/plugins/mixins/Nav";
    import ContextMenuFile from "@/components/ContextMenuFile.vue";
    import OpenFile from '@/components/OpenFile.vue';
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
    import SelectInput from "@/components/Form/SelectInput.vue";
    import ListItem from "@/components/Storage/ListItem.vue";
    export default {
        name: "Storages",
        mixins: [
            FilterView,
            ResourceView,
            NavMixin
        ],
        components: {
            FilterMobileLinks,
            PageLayout,
            ContextMenuFile,
            SelectInput,
            OpenFile,
            ListItem
        },
        data() {
            return {
                filter_show: false,
                entityName: 'client_storage',
                dialogForm: false,
                document_flow_urls_form: [],
                dialogOpenFile: false,
                fileOpen: {},
                invalid: false,

                progress:0,
                progress2:0,
                progress_shop:false,
                date: null,
                search: false,
                nextLink: true,
                color: 'primary',
                dateDialog: false,
                storage: null,
                storages: [],
                sortBy: "id",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 25,
                totalVisiblePag: 5,
                totalStorages: 0,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id']
                },
                loading: false,
                no_data: false,

                Additional: [],
                adminItems: [],
                system_filters: [],
                group2: true,
                selected: [],
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.init();
        },
        methods: {
            async init() {
                await this.getFilters();
                await this.getStorages()
                await this.getTopics()
            },
            async getStorages() {
                var _this = this;
                this.loading = true;
                const {
                    sortBy,
                    sortDir: sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc !== undefined) {
                    params.sortDir = sortDesc ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    params.page = page
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.storage) {
                    params.storage = this.storage;
                }

                if (this.filterValues) {
                    params.fltr = this.filterValues;
                }


                await this.$http
                    .get("client/storage", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.storages = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalStorages = res.body.meta.total
                        this.pageCount = res.body.meta.last_page

                    })
                    .catch(err => {
                        this.storages = []
                        this.totalStorages = 0
                        this.$toastr.error(this.$t('failed_to_get_list_storages'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getTopics() {
                if (this.adminItems.length <= 0) {
                    this.loading = true
                    let params = {}
                    params.active = this.active
                    params.perPage = 1000;
                    params.sortBy = 'title';
                    params.sortDir = 'asc';
                    await this.$http
                        .get("client/topic", {
                            params: params,
                        })
                        .then(res => {
                            this.Additional = res.body.data.map((item) => {
                                return {
                                    ...item,
                                    url: {
                                        path: '/storage',
                                        query: {
                                            filter: {
                                                topic_id: {
                                                    eq: item.id + ''
                                                }
                                            }
                                        }
                                    }
                                };
                            });
                        })
                        .catch(err => {
                            this.Additional = []
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }

            },


        }
    }
</script>

<style>
.td_icon{
    width: 30px;

}

.rowStorage td:nth-child(1){
  width: 5% !important;
}
.rowStorage td:nth-child(2){
    width: 68% !important;
}
.rowStorage td:nth-child(3){
    width: 12% !important;
}
.rowStorage td:nth-child(4){
    width: 9% !important;
}
.rowStorage td:nth-child(5){
    width: 4% !important;
}
</style>

